<div *ngIf="isLoading" class="nodata"><i nz-icon nzType="loading" nzTheme="outline"></i></div>
<div *ngIf="isError" class="nodata">Cannot fetch data, please try again later</div>

<form nz-form *ngIf="!isLoading && formInput" [formGroup]="formInput" class="form-detail" nzLayout="vertical">
  <!--  Shipment Detail-->
  <nz-row>
    <nz-col [nzMd]="24" [nzLg]="{span:16, offset:4}">
      <div class="form-container">
        <div class="flex-space-between bottom40">
          <div class="flex">
            <button *ngIf="!isEditing || !isInputsChange()" nz-button (click)="onBtnComeBack()" nz-tooltip nzTooltipTitle="Back to list page" class="right20">
              <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </button>
            <button *ngIf="isEditing && isInputsChange()" nz-button (nzOnConfirm)="onBtnComeBack()"
              nz-popconfirm nzPopconfirmPlacement="top" nzOkText="Yes" nzCancelText="No"
              nzPopconfirmTitle="Come back without saving?"
              nz-tooltip nzTooltipTitle="Back to list page" class="right20">
              <i nz-icon nzType="arrow-left" nzTheme="outline"></i>
            </button>
            <h4 nz-typography>Post to DAT</h4>
          </div>
          <div *ngIf="!!editId"  style="text-align: right;">
            <button nz-button nzType="primary" [nzLoading]="onProgress" [disabled]="isEditing || isClosed" (click)="onBtnRefresh()"
              nz-tooltip nzTooltipTitle="Bring the post to the top of DAT Dashboard">
              <i nz-icon nzType="retweet" nzTheme="outline"></i>
              Refresh
            </button>
            <div class="text-note" *ngIf="model?.refresh">Last refresh: {{displayDateTime(model.refresh.when)}}</div>
          </div>
        </div>
        <div class="box-with-title">
          <div class="box-title">Shipment Details</div>
          <div class="box-content">
            <!-- Location -->
            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="12">
                <div *ngFor="let key of ['origin']" class="form-item">
                  <div class="single-line">{{ getLabel(key) }} <span *ngIf="isRequired('origin')"
                      class="label-mark-required"></span></div>
                  <input #powerLaneInputFrom (input)="onInputChanged($event, key)" [nzAutocomplete]="autoComplete1"
                    [placeholder]="'Enter to search'" [formControlName]="key" id="{{key}}-origin" nz-input>
                  <nz-autocomplete #autoComplete1>
                    <nz-auto-option *ngFor="let item of listAddressAutoComplete[key + '-origin']; let i = index"
                      [nzValue]="item">
                      {{ item }}
                    </nz-auto-option>
                  </nz-autocomplete>
                </div>
              </nz-col>
              <nz-col [nzSpan]="12">
                <div *ngFor="let key of ['destination']" class="form-item">
                  <div class="single-line">{{ getLabel(key) }} <span *ngIf="isRequired('destination')"
                      class="label-mark-required"></span></div>
                  <input #powerLaneInputTo (input)="onInputChanged($event, key)" [nzAutocomplete]="autoComplete2"
                    [placeholder]="'Enter to search'" [formControlName]="key" id="{{key}}-destination" nz-input>
                  <nz-autocomplete #autoComplete2>
                    <nz-auto-option *ngFor="let item of listAddressAutoComplete[key + '-destination']; let i = index"
                      [nzValue]="item">
                      {{ item }}
                    </nz-auto-option>
                  </nz-autocomplete>
                </div>
              </nz-col>
            </nz-row>
            <div class="bottom10">
              <div class="text-note">*Please search and select the correct city name, state when you got errors "Origin/Destination not found"</div>
            </div>

            <!-- Datetime -->
            <nz-row [nzGutter]="16">
              <!--      pickupFromDate-->
              <nz-col [nzSpan]="6">
                <div *ngFor="let key of ['pickupFromDate']" class="form-item">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired('pickupFromDate')"
                      class="label-mark-required"></span></div>
                  <nz-date-picker [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
                    [nzFormat]="'M/dd/yyyy'" class="full-w"></nz-date-picker>
                </div>
              </nz-col>
              <!--      pickupToDate-->
              <nz-col [nzSpan]="6">
                <div *ngFor="let key of ['pickupToDate']" class="form-item">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired('pickupToDate')"
                      class="label-mark-required"></span></div>
                  <nz-date-picker [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key"
                    [nzFormat]="'M/dd/yyyy'" class="full-w"></nz-date-picker>
                </div>
              </nz-col>
              <!--      pickupFromTime-->
              <nz-col [nzSpan]="6">
                <div *ngFor="let key of ['pickupFromTime']" class="form-item">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired('pickupFromTime')"
                      class="label-mark-required"></span></div>
                  <nz-time-picker [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key" nzFormat="HH:mm"
                    class="full-w"></nz-time-picker>
                </div>
              </nz-col>
              <!--      dropFromTime-->
              <nz-col [nzSpan]="6">
                <div *ngFor="let key of ['dropFromTime']" class="form-item">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired('dropFromTime')"
                      class="label-mark-required"></span></div>
                  <nz-time-picker [nzPlaceHolder]="getPlaceHolder(key)" [formControlName]="key" nzFormat="HH:mm"
                    class="full-w"></nz-time-picker>
                </div>
              </nz-col>
            </nz-row>
            <br />
            <!-- Equiments -->
            <h5 nz-typography>Equipment Details</h5>
            <div *ngFor="let key of ['loadType']" class="form-item">
              <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                class="label-mark-required"></span>
              </div>
              <nz-radio-group [formControlName]="key">
                <label nz-radio nzValue="FTL">Full truckload</label>
                <label nz-radio nzValue="LTL">Less than truckload</label>
              </nz-radio-group>
            </div>

            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="12">
                <div *ngFor="let key of ['equipmentType']" class="form-item">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                  </div>
                  <nz-select [formControlName]="key" [nzPlaceHolder]="getPlaceHolder(key)" nzAllowClear
                    nzBackdrop="true" style="width: 100%;" [nzOptions]="equipmentType">
                  </nz-select>
                </div>
              </nz-col>
              <nz-col [nzSpan]="6">
                <div *ngFor="let key of ['lengthFeet']" class="form-item" style="min-width: 120px;">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                  </div>
                  <nz-input-group nzSize="default">
                    <input nz-input type="number" [formControlName]="key" placeholder="Length ft" />
                  </nz-input-group>
                </div>
              </nz-col>
              <nz-col [nzSpan]="6">
                <div *ngFor="let key of ['weightPounds']" class="form-item" style="min-width: 120px;">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                  </div>
                  <nz-input-group nzSize="default">
                    <input nz-input type="number" [formControlName]="key" placeholder="Weight Ibs" nzSize="default" />
                  </nz-input-group>
                </div>
              </nz-col>
            </nz-row>

            <nz-row [nzGutter]="16">
              <nz-col [nzSpan]="12">
                <div *ngFor="let key of ['defaultComment', 'additionalComment']" class="form-item">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                  </div>
                  <textarea nz-input [rows]="3" [formControlName]="key" placeholder="Comments"></textarea>
                </div>
              </nz-col>

              <nz-col [nzSpan]="12">
                <div *ngFor="let key of ['commodity']" class="form-item" style="min-width: 200px;">
                  <div class="form-label-v2">{{ getLabel(key) }} <span *ngIf="isRequired(key)"
                      class="label-mark-required"></span>
                  </div>
                  <nz-input-group nzSize="default">
                    <input nz-input type="text" [formControlName]="key" placeholder="Commodity" />
                  </nz-input-group>
                </div>
              </nz-col>
            </nz-row>
            <br />
            <!-- Contact information -->
            <!-- <h5 nz-typography>Contact Information</h5>
            <nz-row>
              <nz-col [nzSpan]="12">
                <div> Select at least one or more contact methods to share with carriers on your posts</div>
                <div class="light"> Contact info can be update in <a>your profile</a></div>
              </nz-col>
              <nz-col [nzSpan]="12">
                <div *ngFor="let key of ['contactMethods']" class="form-item" style="margin-left: 30px">
                  <nz-checkbox-group class="vertical-checkbox-group" [formControlName]="key">
                  </nz-checkbox-group>
                </div>
              </nz-col>
            </nz-row> -->
          </div>
        </div>

        <!--  Require Tracking -->
        <div class="box-with-title">
          <div class="box-title"></div>
          <div class="box-content">
            <div *ngFor="let key of ['isTrackingRequired']" class="form-item">
              <div>
                <div>
                  <label nz-checkbox [formControlName]="key">
                    <div class="single-line bold">{{ getLabel(key) }}</div>
                  </label>
                </div>
              </div>
            </div>
            <div class="light notice">Let carriers know tracking is required for this shipment</div>
          </div>
        </div>

        <!--  Footer buttons -->
        <div style="height: 50px;">
          <div *ngIf="!isAdminReadOnlyRole" detail-footer-buttons labelButtonCreate="Post"
            [isCreateNew]="isCreateNew" [isEditing]="isEditing" [onProgress]="onProgress" [needUpdate]="needUpdate"
            (onEdit)="onBtnEdit()" (onSave)="onBtnSave()" (onCancel)="onBtnCancel()" (onBack)="onBtnComeBack()"
            [shouldDisableBtnEdit]="isClosed" [shouldShowBtnComeBack]="isCreateNew" labelButtonComeBack = 'Cancel'
            >
          </div>
        </div>
        <div style="text-align: right;">
          <div class="text-note" *ngIf="model?.insert">{{isAutoPost ? 'Auto created' : 'Created'}} at: {{displayDateTime(model.insert.when)}}</div>
          <div class="text-note" *ngIf="model?.update?.when !== model?.insert?.when">Updated at: {{displayDateTime(model.update.when)}}</div>
          <div class="text-note" *ngIf="model?.close">Closed at: {{displayDateTime(model.close.when)}}</div>
          <div class="text-note" *ngIf="model?.close">**Closed posts must be deleted before being re-created!**</div>
          <div class="text-error" *ngIf="model?.error">{{model.error.event || 'Error'}} at: {{displayDateTime(model.error.when)}}</div>
        </div>

      </div>
    </nz-col>
  </nz-row>
</form>
