import { Component, Input } from "@angular/core";
import { Const } from "@app/const/Const";
import { ActivatedRoute } from "@angular/router";
import { BaseComponent } from "@abstract/BaseComponent";
import { InputHelper } from "@app/services/input-helper";
import { EditCarrierLookups } from "../edit-carrier-lookups-dialog";
import { DialogService } from "@dialogs/dialog.service";

@Component({
  selector: '[dat-truck-search]',
  templateUrl: "./index.html",
  styleUrls: ["../detail-carrier-bid.component.scss", "./index.scss"],
})

export class DATTruckSearch extends BaseComponent {
  constructor(protected activatedRoute: ActivatedRoute) {
    super();
  }

  @Input() carrierBidInfo;

  public carrierBidId = undefined;
  public listCarriers = [];
  public isLoading: boolean = false;
  public isEmailSending: boolean = false;

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.carrierBidId = this.carrierBidInfo.id;
    this.onTruckSearching();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  $asMoney = (money) => {
    return InputHelper.formatMoney1(money + '');
  };

  $asCarrierStatusText = (status) => {
    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const statusKey = Object.keys(Const.CarrierStatus).filter((key) => Const.CarrierStatus[key] == status)[0] || "";
    return capitalizeFirstLetter(statusKey);
  };

  onBtnRefresh() {
    this.onTruckSearching(true);
  }

  onSyncDataCarriers() {
    let bidId = this.carrierBidId;
    let url = `${Const.APIURI_CARRIER_LOOKUP}/${bidId}/sync-lookup-to-bid`;
    this.api.POST(url).subscribe(
      (resp) => {
        this.showSuccess('Sync data successful');
      },
      (err) => {
        this.showErr(err);
      }
    )
  }

  onTruckSearching(refresh = false) {
    this.isLoading = true;
    if (!this.carrierBidId) {
      this.showErr('bidId is required.');
      return;
    }
    let url = `${Const.APIURI_CARRIER_LOOKUP}/${this.carrierBidId}/search-by-bid`;
    if(refresh){
      url = url + `?refresh=true`
    }
    this.api.GET(url).subscribe(
      (resp) => {
        this.listCarriers = resp?.data?.list_data;
        this.isLoading = false;
      },
      (err) => {
        this.showErr(err);
        this.isLoading = false;
      }
    );
  }

  sendInviteRegisterToCarrier(carrier) {
    this.isEmailSending = true;
    let url = `${Const.APIURI_CARRIER_LOOKUP}/${this.carrierBidId}/${carrier.id}/send-register-invite`;
    let params = {}
    this.api.POST(url, params).subscribe(
      (resp) => {
        this.isEmailSending = false;
        this.showSuccess('Sent successfull');
      },
      (err) => {
        this.isEmailSending = false;
        this.showErr(err);
      }
    )
  }

  getLastSendInvite(carrier){
    const inviteHistories = carrier?.inviteHistories || [];
    const when = inviteHistories[inviteHistories?.length - 1]?.whenBy?.when;
    if(when){
      return new Date(when).toLocaleString();
    } else {
      return ''
    }
  }

  displayCarrierEquipment(carrier) {
    return carrier?.equipment;
  }

  isRegisteredCarrier(carrier) {
    return carrier?.carrierId ? true : false;
  }

  goToDetailCarrier(carrierId) {
    if(carrierId) {
      return `${Const.routeAdminCarrierList}/${carrierId}`;
    }
    return '';
  }

  onBtnEditItem(carrier) {
    DialogService.openFormDialog1(EditCarrierLookups, {
      nzComponentParams: {
        type: 'answer',
        carrierBidItem: carrier,
        carrierBidInfo: this.carrierBidInfo,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onBtnRefresh()
        }
      }
    })
  }

  onBtnEditNote(carrier) {
    DialogService.openFormDialog1(EditCarrierLookups, {
      nzComponentParams: {
        type: 'note',
        carrierBidItem: carrier,
        carrierBidInfo: this.carrierBidInfo,
        closeOnSuccess: true,
        updateSuccess: (resp) => {
          this.onBtnRefresh()
        }
      }
    })
  }

}