import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "@services/api.service";
import { Const } from "@const/Const";
import { Log } from "@services/log";
import { Subscription } from "rxjs";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DateUtil } from "@services/date-utils";
import { ExtendValidators } from "@app/admin/base/validator";
import { ThirdPartyPost } from "@wearewarp/types/data-model/types/third-party";
import { thirdPartyPostMapper } from "@wearewarp/universal-libs/src/mapper/ThirdPartyPost";
import { VehicleTypeService } from "@services/vehicle.service";

@Component({
  selector: "third-party-post",
  templateUrl: "./third-party-post.component.html",
  styleUrls: ["./third-party-post.component.scss"],
})
export class ThirdPartyPostComponent extends BaseFormItem {
  @Input() data: ThirdPartyPost;

  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  @Output() onBack: EventEmitter<any> = new EventEmitter<any>();

  public isError = false;
  public isLoading = false;
  public listAddressAutoComplete: any = {};
  private subAddressSuggestion: Subscription;
  private timerAddressSuggestion;
  equipmentType = [];
  contactInfoOption = [];

  carrierBidId: string;
  editId: string;
  isClosed = false;

  protected formGroupDeclaration: FormGroupDeclaration = {
    origin: {
      label: "Origin (City, State)",
      placeHolder: "Origin (City, State)",
      required: true,
      type: "string",
    },
    destination: {
      label: "Destination (City, State)",
      placeHolder: "Destination (City, State)",
      required: true,
      type: "string",
    },
    pickupFromDate: {
      label: "Pickup Earliest",
      placeHolder: "Pickup Earliest",
      required: true,
      type: "date",
      getValue: (val) => this.formatDate(val),
    },
    pickupToDate: {
      label: "Pickup Latest",
      placeHolder: "Pickup Latest",
      required: false,
      type: "date",
      getValue: (val) => this.formatDate(val),
    },
    pickupFromTime: {
      label: "Pickup Hours",
      placeHolder: "Pickup Hours",
      required: false,
      type: "time",
      getValue: (val) => this.formatDate(val),
    },
    dropFromTime: {
      label: "Drop Off Hours",
      placeHolder: "Drop Off Hours",
      required: false,
      type: "time",
      getValue: (val) => this.formatDate(val),
    },
    loadType: {
      label: "Load Type",
      required: true,
      type: "string",
    },
    equipmentType: {
      label: "Equipment Type",
      required: true,
      type: "array",
    },
    lengthFeet: {
      label: "Length (ft)",
      required: true,
      type: "number",
    },
    weightPounds: {
      label: "Weight (Ibs)",
      required: true,
      type: "number",
    },
    defaultComment: {
      label: "1st comment",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Comments must be less than or equal 70 characters"),
    },
    additionalComment: {
      label: "2nd comment",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Comments must be less than or equal 70 characters"),
    },
    commodity: {
      label: "Commodity",
      required: false,
      type: "string",
      validators: ExtendValidators.maxLength(70, "Commodity must be less than or equal 70 characters"),
    },
    isTrackingRequired: {
      label: "Required Tracking",
      required: false,
      type: "boolean",
    },
    bidId: {
      label: "Bid Id",
      required: false,
      type: "string",
    },
  };

  vehicleTypeService: VehicleTypeService;

  constructor(api: ApiService) {
    super();
    this.api = api;
    this.vehicleTypeService = new VehicleTypeService(this.api);
  }

  get shouldCreateFormImmediately() {
    return false;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getEquipment();
  }

  protected beforeBindModel(model): any {
    return thirdPartyPostMapper.mapToFormData(model);;
  }

  ngOnChanges(): void {
    if (!this.data) return;

    // const formData = thirdPartyPostMapper.mapToFormData(this.data);

    this.createFormInput(this.data);

    this.editId = this.data.id || "";
    if (this.editId) {
      this.model = this.data;
      this.isClosed = !!this.data?.close?.when;
      if (this.isClosed) this.setEnableFormGroup(false);
      else this.onBtnEdit();
    }
  }

  get isCreateNew(): boolean {
    return !this.editId;
  }

  getApiUrl() {
    return Const.APIURI_DAT_V2;
  }

  buildUrl(): string {
    let url = this.getApiUrl();
    if (!url) return url;
    let id = (<any>this.model)?.id;
    if (id) {
      url = `${url}/${id}`;
    }
    return url;
  }

  onCreateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showSuccess("Post successfully");
    }
    this.editId = resp.data?.id;
    this.onBack.emit();
  }

  onUpdateSuccess(resp) {
    // this.fileToUpload = {};
    if (resp && resp.ui_message) {
      this.showInfo(resp.ui_message);
    } else {
      this.showInfo("The item has been updated successfully.");
    }
    this.isEditing = false;
  }

  onBtnComeBack() {
    this.onBack.emit();
  }

  onBtnCancel() {
    this.bindDataModel(this.model);
    this.isEditing = false;
  }

  onBtnDelete() {
    const url = this.buildUrl();
    this.onProgress = true;
    this.api.DELETE(url).subscribe(
      (resp) => {
        this.showInfo("The item has been deleted successfully.");
        this.onDelete.emit();
      },
      (error) => {
        this.showErr(error);
      }
    );
  }

  onBtnRefresh() {
    const url = this.buildUrl();
    this.onProgress = true;
    this.api.POST(`${url}/refresh`).subscribe(
      (resp) => {
        const data = resp.data;
        if (this.model && data.refresh) {
          this.model.refresh = data.refresh;
        }
        this.showInfo("The item has been refreshed successfully.");
        this.onProgress = false
      },
      (error) => {
        this.showErr(error);
        this.onProgress = false
      },
    );

  }

  onBtnEdit() {
    super.onBtnEdit();
    this.getItemByKey("origin").disable();
    this.getItemByKey("destination").disable();
  }

  getEquipment() {
    this.vehicleTypeService.listAll(
      (data) => {
        const types = data.filter((it) => it.active !== false);
        const allTypes = {};
        for (let t of types) {
          allTypes[t.code] = t;
        }
        this.equipmentType = data
          .filter((it) => it.active !== false)
          .map((t) =>
            Object.assign(
              {},
              {
                label: t.name,
                groupLabel: allTypes[t.parent]?.name,
                value: t.code,
                disabled: !t.selectable,
              }
            )
          );
      },
      () => {}
    );
  }

  getEquipmentType(type) {
    return `${type?.label} - ${type?.value}`
  }

  getContactInfo(data) {
    this.contactInfoOption = data.map((item) => {
      return {
        label: `${item?.value} (${item.method})`,
        value: item?.value,
      };
    });
  }

  formatDate(date) {
    return DateUtil.convertLocalTime(date, DateUtil.getLocalTimeZone())
  }

  private tryAddressSuggestion(elm: HTMLInputElement) {
    clearTimeout(this.timerAddressSuggestion);
    let userInput = elm.value;
    if (!userInput) {
      this.subAddressSuggestion?.unsubscribe();
      this.listAddressAutoComplete[elm.id] = [];
      return;
    }
    this.timerAddressSuggestion = setTimeout(() => this.doAddressSuggestion(elm, userInput), 100);
  }

  onInputChanged(event, key) {
    switch (key) {
      case "origin":
      case "destination":
        return this.tryAddressSuggestion(event.target);
      default:
        return super.onInputChanged(event, key);
    }
  }

  private doAddressSuggestion(elm: HTMLInputElement, userInput: string) {
    this.subAddressSuggestion?.unsubscribe();
    // if (this.isProduction) {
    //   const query = {
    //     query: userInput,
    //     noZone: true,
    //     noState: true,
    //   }
    //   this.subAddressSuggestion = this.api.POST(`${Const.APIURI_DAT_V2}/search/location`, query).subscribe(
    //     resp => {
    //       this.listAddressAutoComplete[elm.id] = resp.data.list_data.map(it => `${it.city}, ${it.state}`);
    //     }, err => {
    //       Log.e('doAddressSuggestion failed. ', err);
    //     }
    //   );
    //   return;
    // }
    this.subAddressSuggestion = this.api.searchUsCities(userInput).subscribe(
      (resp) => {
        this.listAddressAutoComplete[elm.id] = resp.data.list_data.map((it) => `${it.city}, ${it.stateCode}`);
      },
      (err) => {
        Log.e("doAddressSuggestion failed. ", err);
      }
    );
  }

  isInputsChange() {
    return this.isFormDataChanged();
  }

  get isAutoPost() {
    return this.data?.autoPost || false;
  }
}
