import { Component } from "@angular/core";
import { BaseList } from "@app/admin/base/list";
import { ActivatedRoute } from "@angular/router";
import { Const } from "@const/Const";
import { ApiService } from "@services/api.service";
import { DateUtil } from "@services/date-utils";
import { TimeWindow } from "@wearewarp/types/data-model";
import { VehicleTypeService } from "@services/vehicle.service";

@Component({
  selector: 'list-dat-post',
  templateUrl: './list.html',
  styleUrls: ['./list.scss']
})

export class ListDatPost extends BaseList {
  isLoading: boolean = false;
  isPostRefreshing: boolean = false;
  refreshPostId: string = "";
  carrierBidId: string;
  bid: any;
  job: any;
  listPost: any = [];
  equipmentType = [];
  displayInfos: any = [];
  displayKeys = [
    { key: 'postTitle', label: "" },
    { key: 'load', label: "Load" },
    { key: 'comments', label: "Comments" }
  ]

  vehicleTypeService: VehicleTypeService;

  constructor(activatedRoute: ActivatedRoute, api: ApiService) {
    super();
    this.activatedRoute = activatedRoute;
    this.api = api;
    this.carrierBidId = this.getRouteParam(this.activatedRoute, "id");
    this.vehicleTypeService = new VehicleTypeService(this.api);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getBid();
    this.getEquipment();
  }

  get routeCode() {
    return this.job?.code || '';
  }

  protected getApiUrl(): string {
    return `${Const.APIURI_DAT_V2}/carrier-bid/${this.carrierBidId}`;
  }

  onGetDataSucceeded(resp) {
    this.listPost = resp?.data?.list_data || [];
    if (this.listPost?.length == 0) {
      this.onBack();
    }
    this.buildDisplayInfo();
  }

  onBack() {
    this.router.navigate([this.routeAdminCarrierSales, this.carrierBidId]);
  }

  buildDisplayInfo() {
    if (!this.listPost) return;
    this.displayInfos = [];
    for (let post of this.listPost) {
      let displayPostInfo = {
        ...post,
        load: this.getPostLoadInfo(post),
        comments: this.getPostComments(post),
        commodity: this.getPostCommodity(post),
        referenceId: this.getPostReference(post),
        pickupAddr: this.getRouteOrigin(post),
        dropoffAddr: this.getRouteDestination(post),
        pickupTime: this.getPickupTime(post),
        dropoffTime: this.getDropoffTime(post),
      }
      this.displayInfos.push(displayPostInfo);
    }
  }

  getEquipment() {
    this.vehicleTypeService.listAll(
      (data) => {
        const types = data.filter((it) => it.active !== false);
        const allTypes = {};
        for (let t of types) {
          allTypes[t.code] = t;
        }
        this.equipmentType = data
          .filter((it) => it.active !== false)
          .map((t) =>
            Object.assign(
              {},
              {
                label: t.name,
                groupLabel: allTypes[t.parent]?.name,
                value: t.code,
                disabled: !t.selectable,
              }
            )
          );
        this.buildDisplayInfo();
      },
      () => {}
    );
  }

  getBid() {
    this.isLoading = true;
    this.api.GET(`${Const.APIURI_CARRIER_BIDS}/${this.carrierBidId}`).subscribe(
      (response) => {
        this.bid = response.data;
        this.job = this.bid?.job;
      },
      (error) => {
        this.showErr(error);
      }
    );
  }


  onBtnCreateNewPost() {
    this.onBtnAdd();
  }

  getPostItemLabel(key) {
    switch (key) {
      case 'load':
        return 'Load';
      case 'comments':
        return 'Comments';
      case 'commodity':
        return 'Commodity';
      case 'referenceId':
        return 'Reference Id';
    }
  }

  getPostLoadInfo(post) {
    let equipment = this.equipmentType.filter(it => it.value === post?.equipment?.code)?.[0];
    let loadType = post?.loadType;
    let vehicleType = `${post?.length || 0} ft`;
    let weight = `${post?.weight || 0} lbs`;
    return `${loadType}, ${equipment?.label} - ${equipment?.value}, ${vehicleType}, ${weight}`;
  }
  getPostComments(post) {
    return post?.comments?.[0] || '';
  }
  getPostAdditionalComments(post) {
    return post?.comments?.[1] || '';
  }
  getPostCommodity(post) {
    return post?.commodity || '';
  }
  getPostReference(post) {
    return post?.referenceId || '';
  }
  getRouteOrigin(post) {
    return post?.origin ? [post?.origin?.city, post?.origin?.state].join(", ") : '';
  }
  getRouteDestination(post) {
    return post?.destination ? [post?.destination?.city, post?.destination?.state].join(", ") : '';
  }
  getPickupTime(post){
    let timeWindow: TimeWindow = {
      from: post?.pickDt?.fromDate,
      to: post?.pickDt?.toDate,
    }
    return this.getDisplayTimeWindow(timeWindow, post?.pickDt?.fromTime);
  }

  getDropoffTime(post) {
    let timeWindow = undefined;
    return this.getDisplayTimeWindow(timeWindow, post?.dropDt?.fromTime);
  }

  getDisplayTimeWindow(timeWindow: TimeWindow | undefined, hours) {
    let timezone = DateUtil.getLocalTimeZone();
    let dateWindow = '';
    let deliveryHours = '';
    if (timeWindow) {
      dateWindow = DateUtil.displayTimeWindow(timeWindow, {
        timezone: timezone,
        formatDateOnly: 'MMM D, YYYY',
        format: "MMM D, YYYY",
      })
    }
    if (hours) {
      deliveryHours = DateUtil.displayLocalTime(hours, { timezone, format: 'HH:mm'});
    }
    return `${dateWindow} ${deliveryHours}`.trim();
  }

  onBtnDeletePost(post) {
    this.confirmYesNo(`Are you sure you want to delete this post?`, () => {
      this.isLoading = true;
      let url = `${Const.APIURI_DAT_V2}/${post?.id}`;
      this.api.DELETE(url).subscribe(
        (resp) => {
          this.onBtnRefresh();
          this.isLoading = false;
        },
        (err) => {
          this.showErr(err);
          this.isLoading = false;
        }
      )
    })
  }

  onBtnEditPost(post) {
    let url = this.router.url.split('?')[0];
    this.router.navigate([url, post.id]);
  }

  isRefreshingPostId(id) {
    return this.isPostRefreshing && this.refreshPostId === id;
  }

  onBtnRefreshPost(post, idx?) {
    this.isPostRefreshing = true;
    this.refreshPostId = post.id;
    let url = `${Const.APIURI_DAT_V2}/${post?.id}/refresh`;
    this.api.POST(url).subscribe(
      (resp) => {
        this.onBtnRefresh();
        this.isPostRefreshing = false;
        this.refreshPostId = "";
        this.showSuccess(`Post ${idx+1} refreshed.`)
      },
      (err) => {
        this.showErr(err);
        this.isPostRefreshing = false;
        this.refreshPostId = "";
      }
    )
  }

  getPostLatestRefreshTime(post) {
    let refreshedTime = post?.refresh?.when;
    return DateUtil.dateToString(refreshedTime, 'HH:mm, DD MMM')
  }

  getPostCreatedTime(post) {
    let insertedTime = post?.insert?.when;
    return DateUtil.dateToString(insertedTime, 'HH:mm, DD MMM')
  }

}
