<div class="calendar-same-lane">
  <div *ngIf="loading" loading-bar class="loading"></div>
  <nz-calendar *ngIf="!loading" [nzFullscreen]="false" [nzDateFullCell]="dateCellTpl">
  </nz-calendar>
</div>
<ng-template #dateCellTpl let-date>
  <span *ngIf="!isHighlight(date)">{{ date | date:'d'}}</span>

  <span *ngIf="isHighlight(date)" [ngClass]="{highlight: isHighlight(date), self: hasSelf(date)}" nz-popover
    [nzPopoverContent]="popContent" (nzPopoverVisibleChange)="onChangeDate($event, date)" style="z-index: 1000;">{{ date | date:'d'}}</span>
</ng-template>

<ng-template #popContent>
    <nz-collapse *ngIf="popupContent.self">
      <nz-collapse-panel [nzHeader]="'This load'" [nzShowArrow]="false" [nzActive]="true">
        <b>Load ID: </b>{{ popupContent.self.job?.code }}<br/>
        <b>Carrier: </b> {{ popupContent.self.job?.carrier?.basicInfo?.name || 'N/A' }}<br/>
        <div *ngIf="popupContent.self?.job?.assignedCarrier">
          <b>Accepted Cost: </b> {{ formatMoney(popupContent.self.job?.assignedCarrier?.cost?.grandTotal + '') }}
        </div>
      </nz-collapse-panel>
    </nz-collapse>
    <nz-collapse *ngIf="popupContent.data && popupContent.data?.length">
      <nz-collapse-panel [nzHeader]="'Same lane load'" [nzShowArrow]="false" [nzActive]="true">
        <ng-container *ngFor="let item of popupContent.data; let i=index">
          <div>
            <b>Load ID: </b> <a [href]="'/dashboard/dispatch/' + item.job.id" target="_blank">{{ item.job?.code }}</a><br/>
            <b>Carrier: </b> {{ item.job?.carrier?.basicInfo?.name || "N/A" }}<br/>
            <div *ngIf="item?.job?.assignedCarrier">
              <b>Accepted Cost: </b> {{ formatMoney(item.job?.assignedCarrier?.cost?.grandTotal + '') }}
            </div>
            <a [href]="'/dashboard/carrier-sales/' + item.bid?.id" target="_blank">View Detail</a>
          </div>
          <nz-divider *ngIf="i + 1 < popupContent.data.length"></nz-divider>
        </ng-container>
        <div *ngIf="popupContent.more != 0">
          <nz-divider></nz-divider>
          <b>... and +{{ popupContent.more }} routes</b>
        </div>
      </nz-collapse-panel>
    </nz-collapse>
</ng-template>
